//--------------
//  Main Layout
//--------------

// PACKAGES
import React from "react"

// COMPONENTS
import Header from "../components/organisms/header/Header-thankyou"
import { Helmet } from "react-helmet"

// COMPONENT BUILD
const ThakYou = ({ children }) => (
    // JSX TEMPLATE
    <div>
        <Helmet
            defer={false}
            defaultTitle="Informeasy | House manual booklet for vacation rental">
            <html lang="en" />
            <link rel="canonical" href="https://www.informeasy.com/" />
            <meta
                name="viewport"
                content="initial-scale=1, width=device-width"
            />
            <noscript>
                Please eneable Javascript or use a modern web browser!
            </noscript>
            <meta
                name="description"
                content="Need more than just a free template? Improve guest experience with a custom made printed house manual booklet that provides information about your property, the area, house rules, safety and recommendations. No monthly instalments. Get your house manual designed and delivered to your doorstep."></meta>
        </Helmet>
        <Header />
        {children}
    </div>
)

export default ThakYou

//------------------------
// Header-terms Component
//------------------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"

// COMPONENTS
import Logo from "../../../assets/logos/ie-base-code.svg"

// COMPONENT BUILD
const Navbar2 = () => {
    
    // JSX TEMPLATE
    return (
        <header>
            <nav className="px-4 py-2 bg-white navbar fixed-top navbar-expand-md navbar-light border-bottom border-ie-lighter py-md-3">
                <div className="container">
                    <Link to="/">
                    <img className="logo-size" src={Logo} alt="Informeasy" />
                    </Link>
                    <div style={{height:`48px`, width: `4px`}}>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar2

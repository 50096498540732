//------------------
// Signup Thank You
//------------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

// COMPONENTS
import Layout from "../layout/ThankYouLayout"
import MenuBack from "../assets/icons/menu-back.svg"

// COMPONENT BUILD
class ThankYou extends React.Component {
    
    // JSX TEMPLATE
    render() {
        return (
            <>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
            </Helmet>
            <Layout>
                <section id="pricing">
                <div className="container px-4 text-center vh-100 col-12 d-flex flex-column justify-content-center align-items-center mb-n11">
                    <div className="align-items-center">
                        <h2 className="mb-6 text-center underline">
                            Your request has been sent!
                        </h2>
                        <p className="mb-8 text-center lead lead-small text-muted font-italic">
                            We are thrilled to see that you are interested in our Home Guide Booklet! <span role="img" aria-label="Thrilled emoji" style={{fontSize:`40px`, verticalAlign: `middle`, lineHeight: `1em`}}>👐</span>{" "}
                        </p>
                        <Link className="py-6 nav-link menu-link text-ie-muted-link navbar-light mr-md-5 py-md-2"
                        to="/"
                    >
                        <img className="pb-1 mr-3 d-inline-block position-relative" src={MenuBack} alt="Arrow-icon"/>
                        Back to homepage
                    </Link>
                    </div>
                </div>
            </section>
            </Layout>
            </>
        )
    }
}

export default ThankYou
